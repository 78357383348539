import * as React from "react"
import { useLocation } from "@reach/router"

import { TagManagerContext } from "../../core/contexts/tagManager"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import Divider from "../../components/divider/divider"
import Card from "../../components/card/card"
import ElevatedButton from "../../components/buttons/elevatedButton"

const description = `As an agile and customer-focused digital business, the development of your digital products, such as your website and native mobile application, should be based on the right capabilities. Our capabilitites are based on user experience and analytics, driven by creativity and technological innovations.`

const ProductDevelopmentPage = () => {
  const location = useLocation()
  const tagManager = React.useContext(TagManagerContext)

  const onLinkClick = (item: { label: string; href: string }) => {
    tagManager?.onLinkClickEvent({
      link_type: "navigation",
      link_url: location.origin + item.href,
      link_name: item.label,
      link_location: "page",
    })
  }

  return (
    <Layout>
      <Seo title="Services | Product Development" description={description} />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Product Development</h4>
          <p className="subtitle1">{description}</p>
        </div>
        <Divider />
        <div className="mt-5 mb-5 pt-5 pb-5 mw-800">
          <h6>Web Design &amp; Development</h6>
          <p>
            For most businesses, their website is one of the most valuable
            marketing assets. When designing websites, we always approach the
            designs from a business perspective. We believe in accessibility
            and, therefore, place a large emphasis on ensuring that a website is
            enjoyable on mobile, tablets, and desktops.
          </p>
          <div className="mw-600 mt-5 mb-5">
            <Divider />
          </div>
          <h6>Native App Design &amp; Development</h6>
          <p>
            Transform your business processes and customer communications with
            dynamic, integrated mobile applications tailored to your
            business&apos; structure and needs. iPhupha Digital builds beautiful
            native apps on all major platforms and devices (iOS, iPhone, iPad,
            iPod Touch, Android).
          </p>
          <div className="mw-600 mt-5 mb-5">
            <Divider />
          </div>
          <h6>Software Maintenance</h6>
          <p>
            To support and encourage the agile nature of digitally-transformed
            businesses, the process of changing, modifying, and updating
            software to keep up with customer needs is offered through a
            maintenance plan. Software maintenance is done for several reasons
            including improving the software overall, correcting issues or bugs,
            to boost performance, and more.
          </p>
          <div className="mw-600 mt-5 mb-5">
            <Divider />
          </div>
          <h6>Technology &amp; Data Integrations</h6>
          <p>
            Data is everything. We integrate with leading technology companies —
            like SAP, Adobe, Salesforce, and more — to power personalized
            experiences with all the data at your fingertips and make your teams
            more efficient. We offer these tech and data integrations to create
            personal connections at scale.
          </p>
        </div>
      </section>
      <div className="container">
        <Divider />
      </div>
      <section className="container pt-5 pb-5">
        <Card isPrimary={true} canHover={false}>
          <div className="pt-2 pb-2">
            <div className="row justify-space-between align-center">
              <h6 className="mb-3">How can we help you?</h6>
              <span className="text-black">
                <div
                  onClick={() =>
                    onLinkClick({
                      label: "Get in touch with us",
                      href: "/contact-us",
                    })
                  }
                >
                  <ElevatedButton
                    label="Get in touch with us"
                    href="/contact-us"
                  />
                </div>
              </span>
            </div>
          </div>
        </Card>
      </section>
      <div className="container">
        <Divider />
      </div>
    </Layout>
  )
}

export default ProductDevelopmentPage
